export const theme = {
    colors: {
        // primary: '#0f172a',
        primary: '#fffff',
        primary_light: '#26298c',
        secondary: '#26298c',
        // blue: '#7eadfc',
        white: '#fff',
        black: '#000',
        para_text_color: '#000',
        inputfill: '#fff'
    },
    fonts: {
        family: 'DM Sans',
        weight: {
            regular:400,
            medium:500,
            bold: 700
        }
    },
    breakpoints: {
        mobile: '720px'
    }
    
}