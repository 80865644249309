import styled from "styled-components";

export const SkillsCardContainer = styled.div`
    display: grid;
    grid-template-columns: auto auto;
    grid-gap: 0.1rem;
    padding: 0 2%;
    padding-top: 4rem; /* Add top padding only */
    padding-left: 2%; /* Keep other padding as needed */
    padding-right: 2%;

    @media(max-width: ${({theme})=> theme.
        breakpoints.mobile}){
            display: block;
            padding: 0; 
        }
`

export const SkillsCard = styled.div`
    width: 150px;
    height: 150px;
    border-radius: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color:rgb(90, 205, 201)
    color: white;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.06);
    transition: transform 0.3s ease, box-shadow 0.3s ease;

    /* Add padding for better content spacing */
    padding: 1rem;

    &:hover {
        transform: translateY(-5px);
        box-shadow: 0 10px 15px rgba(0, 0, 0, 0.15), 0 4px 6px rgba(0, 0, 0, 0.1);
        background-color: rgb(82, 82, 145); /* Slightly darker shade on hover */
    }

    @media(max-width: ${({ theme }) => theme.breakpoints.mobile}) {
        width: 100%;
        margin-top: 2rem;
    }
`
